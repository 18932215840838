import React, { Component } from 'react';
import { StaticQuery, graphql, navigate } from 'gatsby';
import ls from 'local-storage'
import { removeFromArray, numberToMoney } from './helpers'
import { decodeEntities, isBrowser  } from '../../utils/helpers';
import GatsbyLink from '../GatsbyLink'
import { FaTrash, FaTimes } from 'react-icons/fa';
import './Quote.scss'
import { Image } from '../Image'
import RenderContent from '../RenderContent'
import { QuoteNavigation } from './QuoteNavigation'
import { FaCaretLeft, FaCircleNotch } from 'react-icons/fa';

const ClientTotals = ({userState, quoteTotalWa, quoteTotalNsw}) => {
  const total = userState === 'wa' ? quoteTotalWa : quoteTotalNsw
  if (!total) return (
    <div className="totals">
      <div>Your quote total will be confirmed by the below account manager.</div>
      <h3>TBC</h3>
    </div>
  )
  return (
    <div className="totals">
      <div>
        <div>Total (ex GST): { numberToMoney(total)}</div>
        <h3>Total (inc GST): {numberToMoney(total * 1.1)}</h3>
      </div>
    </div>
  )
}

const AdminTotals = ({quoteTotalWa, quoteTotalNsw}) => {
  if (!quoteTotalWa || !quoteTotalNsw) return (
    <div className="totals">
      <div>Total TBC</div>
    </div>
  )
  return (
    <div className="totals">
      <div>
        <div>Total (ex GST): {numberToMoney(quoteTotalWa)}</div>
        <h3>Total (inc GST): {numberToMoney(quoteTotalWa * 1.1) }</h3>
      </div>
    </div>
  )
}

class QuoteList extends Component {
  constructor(props) {
    super(props);
    const { quotePass } = props
    this.state = {
      quote: quotePass ? quotePass : ls.get('quote') ? ls.get('quote') : null,
      showPrice: ls.get('showPrice') ? ls.get('showPrice') : null,
      loaded: false,
      error: null,
      rawText: false
    }
  }

  componentDidMount() {
    if (isBrowser) {
      const urlParams = window.location.search ? new URLSearchParams(window.location.search) : null;
      
      const type = urlParams && urlParams.get('type');
      if (type) { this.setState({ activeQuoteType:type }) }
      
      const showPrice = urlParams && urlParams.get('showPrice');
      if (showPrice == 'true') { 
        ls.set('showPrice', true) 
        this.setState({ showPrice:true })
      }
      const rawText = urlParams && urlParams.get('rawText');
      if (rawText) {
        this.setState({ rawText:true })
      }
    }
  }

  rawHtmlFormat() {
    const { data } = this.props
    const { products } = data
    const { quote } = this.state
    let quoteTotalWa = 0
    let quoteTotalNsw = 0
    return (
      <>
      
        {quote && quote.map((item, index) => {

          const thisProduct = item.productId && products.edges.filter(i => i.node.wordpress_id === item.productId)
          const packaging = products.edges.filter(i => i.node.wordpress_id === item.packaging)
          const extrasOne = item.extrasOne && products.edges.filter(i => i.node.wordpress_id === item.extrasOne)
          const extrasTwo = item.extrasTwo && products.edges.filter(i => i.node.wordpress_id === item.extrasTwo)

          quoteTotalWa = quoteTotalWa + item.totalWa
          quoteTotalNsw = quoteTotalNsw + item.totalNsw

          if (thisProduct) {
            return (
              <div key={index}>
                <h4>{item.quoteType.toUpperCase()}</h4>

                {thisProduct && thisProduct[0] && 
                <>
                    <div><strong>{decodeEntities(thisProduct[0].node.title)}</strong> {thisProduct[0].node.acf.displayTitle}</div>
                    <div>{item.quoteType === 'usb' && `Capacity: ${item.usbCapacity}`}</div>
                    <div>{item.quoteType === 'usb' && `${item.usbPrinting === 'Yes' ? 'Printed: Yes' : ' Printing: No'}`}</div>
                </>
                }
                {packaging && packaging[0] && packaging[0].node.title !== 'NA' && <RenderContent className="extra" content={`Packaging: ${packaging[0].node.title}`} />}
                {extrasOne && extrasOne[0] && extrasOne[0].node.title !== 'NA' && <RenderContent className="extra" content={`Extras One: ${extrasOne[0].node.title}`} />}
                {extrasTwo && extrasTwo[0] && extrasTwo[0].node.title !== 'NA' && <RenderContent className="extra" content={`Extras Two: ${extrasTwo[0].node.title}`} />}
                {item.note && <div className="extra">Note: <strong>{item.note}</strong></div>}
                {!item.totalWa &&
                  <div className="warning">This item will require a custom quote. Your request will be submitted and a specialist will provide you with a quote.</div>
                }

                <div>Qty: {item.qty}</div>

                <p>
                  Unit Price (ex GST): {numberToMoney(item.totalWa / item.qty)}<br/>
                  Total (ex GST): {numberToMoney(item.totalWa)}<br/>
                  <b>Total (inc GST): {numberToMoney(item.totalWa * 1.1)}</b>
                </p>
  
              </div>
            )
          }

          if ( ['video-to-dvd','audio-transfer','scanning','film'].includes(item.quoteType) ) {
            let lineItems = []
            for (let [key, thisItem] of Object.entries(item.lineItem)) {
              lineItems.push(thisItem)
            }
            //const transferProduct = products.edges.filter(i => i.node.slug === item.quoteType)

            return (
              <div key={index}>
                <h4>{item.quoteType.toUpperCase()}</h4>
                
                <div>
                  {lineItems && lineItems.map((a, index) => a.qty !== '-' && <div key={index}>{a.qty} x {a.name}</div> )}
                </div>

              
                  <div className="title">{item.productGroup && item.productGroup.name} Transfer</div>
                  {lineItems && lineItems.map((a, index) => a.qty === '-' && <div key={index} className="extra">{a.name}</div> )}
                  
                  {item.note && <RenderContent className="extra" content={`Note: <strong>${item.note}</strong>`}/>}
                  {!item.totalWa &&
                    <div className="warning">This item will require a custom quote. Your request will be submitted and a specialist will provide you with a quote.</div>
                  }

                  <p>Total (ex GST): {numberToMoney(item.totalWa)}<br/>
                  <b>Total (inc GST): {numberToMoney(item.totalWa * 1.1)}</b></p>
              </div>
            )
          }

          return (
            <div key={index}>
              <div>
                <h4>Custom</h4>
                <RenderContent content={item.note}/>
              </div>

              <div className="price">
                <b>Total: TBC</b>
              </div>

            </div>
          )
          })}

        <div className="quote-end">          
          <AdminTotals quoteTotalWa={quoteTotalWa} quoteTotalNsw={quoteTotalNsw}/>
        </div>
      </>
    )
    
  }

  removeThis = (e, item ) => {
    e.preventDefault()
    var quote = removeFromArray(this.state.quote, item);
    ls.set('quote', quote);
    this.setState({
      quote
    });
  }

  addToQuote = (e) => {
    e.preventDefault()
    navigate('/quote/')
  }

  render() {
    const { quote, showPrice, rawText } = this.state
    const { 
      data, 
      quotePass, 
      quoteNo = '00000', 
      quoteDate = '12/12/2020', 
      userName = 'Name', 
      userState = null,
      approveQuote,
      rejectQuote,
      quoteStatus = null,
      statusLoading = null
    } = this.props
    const { products, attendants, quoteType, wordpressAcfOptions } = data
    const { showQuantity, guarantee } = wordpressAcfOptions.options
    let quoteTotalWa = 0
    let quoteTotalNsw = 0
    let firstQuoteAttendant = null
    let firstQuoteAttendantId = null
    let quoteTypeObject = null
    if (quotePass && quote) {
      const firstQuoteType = quote && quote[0] && quote[0].quoteType
      const firstQuoteAttendantObject = attendants && attendants.edges.filter(i => i.node.acf.service && i.node.acf.service.find(a => a.slug === firstQuoteType))
      quoteTypeObject = quoteType && quoteType.nodes.filter(a => a.slug === firstQuoteType);
      firstQuoteAttendant = firstQuoteAttendantObject && firstQuoteAttendantObject[0].node.acf
      firstQuoteAttendantId = firstQuoteAttendantObject && firstQuoteAttendantObject[0].node.wordpress_id
    }
    
    return (
      <>
      {quotePass &&
        <div className="quote-header">
          <div className="quote-number">Quote Number #{quoteNo}</div>
          <p>This quote is valid for 7 days from {quoteDate}.</p>
        </div>
      }
      <div className="quote-list">
        
        {!quotePass && <QuoteNavigation activeStep={2} setQuoteType={this.setQuoteType}/>}

        {!quotePass &&
          <div className="tile-title">
            <span className="title"><strong>Review your quote</strong></span>
            {showPrice && !rawText && <div><a href="?rawText=true">Raw Text</a></div>}
            {showPrice && rawText && <div><a href="./">Quote View</a></div>}
            <span className="have-more">Have more?</span><button className="back-button" onClick={(e) => this.addToQuote(e)}><FaCaretLeft/> Add to your quote</button>
          </div>
        }
        <div className="scroll">
          {rawText ? 
            <div className="text-view">
              {this.rawHtmlFormat(quoteTotalWa, quoteTotalNsw)}
            </div>
          :
            <>
            <table>
              <tbody>
              <tr>
                {showQuantity && <th className="qty">Qty</th>}
                {showQuantity && <th className="qty"></th>}
                <th colSpan="2">Product</th>
                {quotePass && <th className="price">Total</th>}
                {showPrice && !quotePass && <th className="price">Total</th>}
                {!quotePass && <th>Action</th>}
              </tr>
              {quote && quote.map((item, index) => {

                const thisProduct = item.productId && products.edges.filter(i => i.node.wordpress_id === item.productId)
                const packaging = products.edges.filter(i => i.node.wordpress_id === item.packaging)
                const extrasOne = item.extrasOne && products.edges.filter(i => i.node.wordpress_id === item.extrasOne)
                const extrasTwo = item.extrasTwo && products.edges.filter(i => i.node.wordpress_id === item.extrasTwo)

                quoteTotalWa = quoteTotalWa + item.totalWa
                quoteTotalNsw = quoteTotalNsw + item.totalNsw

                if (thisProduct) {
                  return (
                    <tr key={index}>
                      <td className="qty">{item.qty}</td>
                      <td className="times"><FaTimes/></td>
                      <td className="image">
                        {thisProduct[0] &&
                          <div className="thumb">
                            <Image src={thisProduct[0].node.featured_media}/>
                          </div>
                        }
                        {packaging && packaging[0] && packaging[0].node.title !== 'NA' &&
                          <div className="thumb">
                            <Image src={packaging[0].node.featured_media}/>
                          </div>
                        }
                        {extrasOne && extrasOne[0].node.title !== 'NA' &&
                          <div className="thumb">
                            <Image src={extrasOne[0].node.featured_media}/>
                          </div>
                        }
                        {extrasTwo && extrasTwo[0].node.title !== 'NA' &&
                          <div className="thumb">
                            <Image src={extrasTwo[0].node.featured_media}/>
                          </div>
                        }

                      </td>
                      <td className="title">
                        {thisProduct && thisProduct[0] && <div className="title"><strong>{decodeEntities(thisProduct[0].node.title)}</strong> {thisProduct[0].node.acf.displayTitle}{item.quoteType === 'usb' && ` ${item.usbCapacity}`}{item.quoteType === 'usb' && `${item.usbPrinting === 'Yes' ? ' Printed' : ' No Printing'}`}</div>}
                        {packaging && packaging[0] && packaging[0].node.title !== 'NA' && <RenderContent className="extra" content={`Packaging: <strong>${packaging[0].node.title}</strong>`} />}
                        {extrasOne && extrasOne[0] && extrasOne[0].node.title !== 'NA' && <RenderContent className="extra" content={`Extras One: <strong>${extrasOne[0].node.title}</strong>`} />}
                        {extrasTwo && extrasTwo[0] && extrasTwo[0].node.title !== 'NA' && <RenderContent className="extra" content={`Extras Two: <strong>${extrasTwo[0].node.title}</strong>`} />}
                        {item.note && <div className="extra">Note: <strong>{item.note}</strong></div>}
                        {!item.totalWa &&
                          <div className="warning">This item will require a custom quote. Your request will be submitted and a specialist will provide you with a quote.</div>
                        }
                      </td>
                      {quotePass &&
                        <td className="price">
                          {numberToMoney(item.totalWa)}
                        </td>
                      }
                      {showPrice && !quotePass &&
                        <td className="price">
                          {numberToMoney(item.totalWa)}
                        </td>
                      }
                      {!quotePass && <td><button onClick={(e) => this.removeThis(e,item)} className="remove" title="Remove"><FaTrash/></button></td>}
                    </tr>
                  )
                }

                if ( ['video-to-dvd','audio-transfer','scanning','film'].includes(item.quoteType) ) {
                  let lineItems = []
                  for (let [key, thisItem] of Object.entries(item.lineItem)) {
                    lineItems.push(thisItem)
                  }
                  //const transferProduct = products.edges.filter(i => i.node.slug === item.quoteType)

                  return (
                    <tr key={index}>
                      <td className="qty">{lineItems && lineItems.map((a, index) => a.qty !== '-' && <div key={index}>{a.qty}</div> )}</td>
                      <td className="times"><FaTimes/></td>
                      <td>
                        {lineItems && lineItems.map((a, index) => a.qty !== '-' && <div key={index} className="title nowrap">{a.name}</div> )}
                      </td>
                      <td className="title">
                        <div className="title"><strong>{item.productGroup && item.productGroup.name} Transfer</strong>
                        {lineItems && lineItems.map((a, index) => a.qty === '-' && <div key={index} className="extra">{a.name}</div> )}
                        </div>
                        {item.note && <RenderContent className="extra" content={`Note: <strong>${item.note}</strong>`}/>}
                        {!item.totalWa &&
                          <div className="warning">This item will require a custom quote. Your request will be submitted and a specialist will provide you with a quote.</div>
                        }
                      </td>
                      {quotePass &&
                        <td className="price">
                          {numberToMoney(item.totalWa)}
                        </td>
                      }
                      {showPrice && !quotePass &&
                        <td className="price">
                          {numberToMoney(item.totalWa)}
                        </td>
                      }
                      {!quotePass && <td><button onClick={(e) => this.removeThis(e,item)} className="remove" title="Remove"><FaTrash/></button></td>}
                    </tr>
                  )
                }

                return (
                  <tr key={index}>
                    <td colSpan="4" className="custom">
                      <div className="inner">
                        <div><strong>Custom:</strong></div>
                        <RenderContent content={item.note}/>
                      </div>
                    </td>
                    {quotePass && userState &&
                        <td className="price">
                          TBC
                        </td>
                    }
                    {showPrice && !quotePass && <td className="price">TBC</td>}
                    {!quotePass && <td><button onClick={(e) => this.removeThis(e,item)} className="remove" title="Remove"><FaTrash/></button></td>}
                  </tr>
                )

              })}
              </tbody>
            </table>
            <div className="quote-end">          
              {quotePass && userState && <ClientTotals userState={userState} quoteTotalWa={quoteTotalWa} quoteTotalNsw={quoteTotalNsw}/>}
  
              {showPrice && !quotePass && <AdminTotals quoteTotalWa={quoteTotalWa} quoteTotalNsw={quoteTotalNsw}/>}
            </div>
            </>
          }
        </div>
      </div>

      {quotePass &&
        <div className="quote-actions">
          {statusLoading && <div className="loading"><div><FaCircleNotch className="spin" /></div></div>}
          {quoteStatus === 'New' ? 
            <div className="actions">
              <div>
                <span className="note">When rejecting a quote this helps us to know your feedback.</span>
                <button className="action reject" to="/quote/" onClick={(e) => rejectQuote(e, firstQuoteAttendantId)}>Reject</button>
              </div>
              <div>
                <span className="note">Alternatively you can get another quote.</span>
                <GatsbyLink className="action" to="/quote/">Request another quote</GatsbyLink>
              </div>
              <div>
                <span className="note">When you approve the quote your account manager will be in contact with you to arrange the deliver information.</span>
                {quoteTotalWa ? <button className="action approve" to="/quote/" onClick={(e) => approveQuote(e, firstQuoteAttendantId)}>Approve</button> : null}
              </div>
            </div>
          :
            <div className="quote-status">This quote has been <span className={quoteStatus}>{quoteStatus}</span></div>
          }
        </div>
      }

      

      {quotePass && firstQuoteAttendant &&
        <div className="attendant-intro">
          
          <h3>Your account manager</h3>

          <div className="profile">
            <div className="face">
              {firstQuoteAttendant.profilePicture && <div className="profile-picture"><Image src={firstQuoteAttendant.profilePicture}/></div>}
            </div>
            <div className="details">
              <div className="name">{firstQuoteAttendant.fullName && firstQuoteAttendant.fullName}</div>
              <div className="position">{firstQuoteAttendant.role && firstQuoteAttendant.role}</div>
              <div className="contact">
                {firstQuoteAttendant.email && 
                  <div>
                    <strong>Email</strong> // <a href={`mailto:${firstQuoteAttendant.email}`} dangerouslySetInnerHTML={{__html: decodeEntities(firstQuoteAttendant.email)}} />
                  </div>
                }
                {firstQuoteAttendant.phone && 
                  <div>
                    <strong>Phone</strong> // <a href={`tel:${firstQuoteAttendant.phone.replace(/[^A-Z0-9]/gi,'')}`} dangerouslySetInnerHTML={{__html: decodeEntities(firstQuoteAttendant.phone) }} />                    
                  </div>
                }
                {firstQuoteAttendant.address && <span dangerouslySetInnerHTML={{__html: decodeEntities(firstQuoteAttendant.address)}}/> }
              </div>
            </div>
          </div>
          
          

          <div className="message">
            {firstQuoteAttendant.introMessage && <RenderContent content={`<p>Hi ${userName}</p>${firstQuoteAttendant.introMessage}`}/>}
            <p><strong>{firstQuoteAttendant.fullName && firstQuoteAttendant.fullName}</strong></p>
          </div>

          <div className="need-to-know">
            {quoteTypeObject[0] && <div dangerouslySetInnerHTML={{__html: (quoteTypeObject[0].description)}}/>}
          </div>
          
         
        </div>
      }

      {quotePass &&
        <div className="footer">
          <RenderContent className="guarantee" content={guarantee}/>
        </div>
      }


      </>
    )
  }
}



export const Quote = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          wordpressAcfOptions {
            options {
              showPrice
              showQuantity
              pricePrefix
              priceSuffix
              priceDescription
              quote
              quotePage
              whatNextText
              moreInformation
              guarantee
            }
          },
          products: allWordpressWpProducts {
            edges {
              node {
                wordpress_id
                mark_up
                product_categories
                title
                slug
                link
                featured_media {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 400 ) {
                        ... GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                acf {
                  displayTitle
                  productPrice {
                    price
                    qty
                  }
                  minQty
                  maxQty
                  weight
                  supplierName
                  supplierId
                  chip
                  relatedCategory
                  relatedProduct
                }
              }
            }
          },
          attendants: allWordpressWpAttendant {
            edges {
              node {
                wordpress_id
                acf {
                  address
                  email
                  fullName
                  role
                  introMessage
                  phone
                  profilePicture {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 400 ) {
                          ... GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  service {
                    slug
                  }
                }
              }
            }
          }
          quoteType: allWordpressWpQuoteType {
            nodes {
              description
              slug
            }
          }
          markUp: allWordpressWpMarkUp {
            edges {
              node {
                acf {
                  needToKnow
                }
                wordpress_id
              }
            }
          }
        }
      `}
      render={data => <QuoteList {...props} data={data} />}
    />
  );
}
