import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import './Footer.scss'
import GatsbyLink from '../GatsbyLink'
import { SocialLinks } from '../SocialLinks'
import { Logo } from '../../../theme/Logo'
import { decodeEntities } from '../../utils/helpers'
import NewsletterSignUp from '../NewsletterSignUp'

const Footer = (props) => {
  const { data, location = {} } = props
  const {
    footerNavigationExtra,
    footerNavigationServices,
    legalNavigation,
    siteSettings,
    wordpressWpSettings,
  } = data
  const { items: footerNavExtra } = footerNavigationExtra
  const { items: footerNavServices } = footerNavigationServices
  const { items: legalNav } = legalNavigation
  const { navigationInFooter, contactDetailsInFooter } = siteSettings.options

  return (
    <>
      <section className="subscribe">
        <div className="container">
          <div className="wrap">
            <p className="heading">
              Subscribe and be the first to know about our promotions and new
              products
            </p>
            <NewsletterSignUp formId="168"/>
          </div>
        </div>
      </section>
      <footer>
        <div className="footer-main">
          <div className="container">
            <div className="wrap">
              <div className="connect">
                <div className="col-2-up">
                  <div className="logo-container">
                    <Logo />
                  </div>
                </div>
                {siteSettings.options.socialLinksFooter && (
                  <div className="col-2-up">
                    <span className="footer-title">Connect with us</span>
                    <SocialLinks />
                  </div>
                )}
              </div>
              {navigationInFooter && (
                <>
                  <div className="services">
                    <span className="footer-title">Services</span>
                    <ul className="footer-nav">
                      {footerNavServices &&
                        footerNavServices.map((item, index) => (
                          <li key={index}>
                            <GatsbyLink
                              to={item.url}
                              aria-label={item.title}
                              decode
                            >
                              {item.title}
                            </GatsbyLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="info">
                    <span className="footer-title">More info</span>
                    <ul className="footer-nav columns">
                      {footerNavExtra &&
                        footerNavExtra.map((item, index) => (
                          <li key={index}>
                            <GatsbyLink
                              to={item.url}
                              aria-label={item.title}
                              decode
                            >
                              {item.title}
                            </GatsbyLink>
                          </li>
                        ))}
                    </ul>
                  </div>
                </>
              )}
              {contactDetailsInFooter && (
                <div className="contact">
                  <span className="footer-title">Contact</span>

                  <div className="locations">
                    {siteSettings.options.locations.map((item, index) => {
                      return (
                        <div className="location" key={index}>
                          <strong>{item.locationName}</strong>

                          <address>
                            {item.addressStreet && item.addressStreet}
                            {item.addressStreet && <br />}
                            {`${item.addressCity &&
                              item.addressCity}, ${item.addressRegion &&
                              item.addressRegion} ${item.addressPostCode &&
                              item.addressPostCode}`}
                          </address>

                          {item.phone && (
                            <a
                              href={`tel:${item.phone.replace(
                                /[^A-Z0-9]/gi,
                                ''
                              )}`}
                              className="phone"
                              aria-label="Call Now"
                            >
                              {item.phone}
                            </a>
                          )}
                          {item.email && (
                            <button
                              className="email"
                              type="button"
                              onClick={() => {
                                window.location = `mailto:${decodeEntities(
                                  item.email
                                )}`
                              }}
                            >
                              {decodeEntities(item.email)}
                            </button>
                          )}
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="disclaimers">
          <div className="container">
            <div className="wrap">
              <span className="copyright">
                {`© ${new Date().getFullYear()} ${wordpressWpSettings.title}`}
              </span>
              {legalNav && (
                <ul className="navigation">
                  {legalNav.map((item, index) => (
                    <li key={index}>
                      <GatsbyLink to={item.url} decode>
                        {item.title}
                      </GatsbyLink>
                    </li>
                  ))}
                </ul>
              )}
              <span className="madeBy">
                Website by {location.pathname === '/' ? <a href="https://www.distl.com.au/">Distl</a> : 'Distl' }
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        footerNavigationExtra: wordpressWpApiMenusMenusItems(
          slug: { eq: "above-primary-navigation" }
        ) {
          items {
            title
            url
            classes
          }
        }
        footerNavigationServices: wordpressWpApiMenusMenusItems(
          slug: { eq: "primary-navigation" }
        ) {
          items {
            title
            url
          }
        }
        legalNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "legal-navigation" }
        ) {
          items {
            title
            url
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              email
              locationName
              phone
              addressCity
              addressPostCode
              addressRegion
              addressStreet
            }
            footerTagline
            socialLinksFooter
            navigationInFooter
            contactDetailsInFooter
            privacyCookiePolicyNotice
            privacyCookiePolicyNoticeText
          }
        }
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
