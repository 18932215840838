import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import { StaticQuery, graphql, navigate } from 'gatsby';
import ReCAPTCHA from "react-google-recaptcha";
import { objectToQuerystring, slugify } from '../../utils/helpers'
const recaptchaRef = React.createRef();
import * as FormFields from './Fields';
import { FaCircleNotch, FaExclamation } from 'react-icons/fa';
import ls from 'local-storage'

class Index extends Component {
    constructor(props) {
      super(props);
      const { thankYou = null, data, id = null, subscribe = null  } = props
      const { siteSettings, allWordpressWpForm } = data
      var settingsFilter = id && allWordpressWpForm && allWordpressWpForm.nodes.filter(i => i['wordpress_id'] === parseInt(id) )
      var settings = settingsFilter && settingsFilter[0] && settingsFilter[0].formJson && settingsFilter[0].formJson
      this.state = {
        error: null,
        loading: false,
        submitted: false,
        ready: false,
        id: id,
        thankYou: thankYou,
        siteKey: siteSettings.options.googleSiteKey,
        settings: settings && settings.formFields && settings.formFields,
        prefill: settings.autoFill,
        button: settings.button ? settings.button : 'Submit',
        lead: ls.get('autoFill') && settings.autoFill ? ls.get('autoFill') : {
          location: typeof window !== `undefined` ? window.location.href : 'Server',
          recipientAddress: settings && settings.recipientAddress ? settings.recipientAddress : null,
          formId: id,
          [`newsletter-subscribe`]: subscribe
        },
      }
    }

    handleSubmit = ( e ) => {
      e.preventDefault()
      this.captchaSubmit( e )
    }

    checkCaptcha = () => {
      const recaptchaValue = recaptchaRef.current && recaptchaRef.current.getValue() ;
      if ( recaptchaValue ) {
        this.setState({ready: true})
      }else{
        this.setState({ready: false})
      }
      this.setState({ready: true})
    }

    editMyDetails = ( e ) => {
      e.preventDefault()
      this.setState({prefill: false})
    }

    captchaSubmit = async ( e ) => {
      this.setState({ loading: true });
      if (typeof window !== 'undefined') {
        window.dataLayer = window.dataLayer || [];
      }
      //const theFunctionsUrl = `/.netlify/functions/leads`
      const leadFunctionUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/leads/v2/submit`
      const appSecret = process.env.GATSBY_WEB_APP_SECRET
      //Add recipient
      const { lead, prefill } = this.state
      const { location } = lead;
      const saveLeadResponse = prefill ? lead : {}
      //console.log('url:',leadFunctionUrl)
      //console.log('body:',JSON.stringify({secret: appSecret, lead: lead}))
      fetch(leadFunctionUrl, {
        method: 'POST',
        body: JSON.stringify({secret: appSecret, lead: lead})
      })
      .then(response => {
       if (response.status === 200) {
        
        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer.push({
            'event': 'form_submit_success',
            'formLocation': location
          });
        }

         prefill && ls.set('autoFill', lead) //Save lead
         ls.set('quote',null) //Clear quote

         response.json().then(json => {
           //console.log('json:',json);
           if (json.quoteId) {
             setTimeout(function() { //Start the timer
               json.quoteId && navigate(`/quote-view?quote=${json.quoteId}`)
             }.bind(this), 0)
           }else {
            
            this.setState({ submitted: true });
            this.setState({ loading: false, lead: saveLeadResponse });
            if ( this.props.thankYou ) {
              this.props.thankYou !== '' && navigate(this.props.thankYou)
             }
           }
         });

       } else {
         this.setState({ loading: false, error: true, lead: saveLeadResponse });
       }

      })
      .catch(error => {
        console.error('submitForm Error', error)
        this.setState({ loading: false, error: true, lead: lead });
      });
    }

    //Form change
    handleChange(e) {
       let lead = this.state.lead
       //If file
       if (e.target.type === 'file') {

         let fileName = `lead-file-${e.target.name}`
         let file = e.target.files[0];
         lead[fileName] = { data: '', file: '' }

         if (file) {
            const reader = new FileReader(file);
            reader.readAsDataURL(file);
            reader.onload = () => {
              // set image and base64'd image data in component state
              lead[fileName].data = reader.result
              lead[fileName].file = file.name
            }
          }
       } else if ( e.target.type === 'checkbox' ) {
         //Toggle value on off
         lead[e.target.name] === e.target.value ? lead[e.target.name] = '' : lead[e.target.name] = e.target.value
       } else {
         //All other fields
         lead[e.target.name] = e.target.value
       }
       this.setState({lead})
    }

    render() {
      const { thankYouText = '<h3>Thank you!</h3><p>Your message has been sent.</p>', thankYou } = this.props
      const { loading, submitted, error, lead, siteKey, id, settings, button, prefill } = this.state
      const prefillFormat = ['first-name', 'last-name', 'phone', 'email', 'message']

      if (!id) return 'No form id!'

      if (!settings) return 'There is a problem with the JSON!';

      if (submitted && !thankYou) return <div className="lead-form thank-you" dangerouslySetInnerHTML={{__html:thankYouText}} />

      const formatComponentName = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      return (
        <div className="lead-form">
          {error && <div className="error"><div>There was a error <FaExclamation /></div></div>}
          {loading && <div className="loading"><div><FaCircleNotch className="spin" /> Loading...</div></div>}
          <form onSubmit={event => {this.handleSubmit(event)}}>
            <div className={`form-inner${prefill  ? ' prefill' : ''}`}>
              {settings.map((field, index) => {
                const FormComponent = FormFields[formatComponentName(field.type)];
                //Add quote to lead
                if (field.type === 'quoteList') {
                  this.state.lead['quote'] = ls.get('quote')
                }

                return (
                  <FormComponent key={index} field={field} value={lead} handleChange={e => this.handleChange(e)} />
                )
        
              })}
              <div className="captcha-wrapper">
                <button onMouseOver={e => this.checkCaptcha()} className={`submit${this.state.ready ? ' ready' : ' not-ready'}`}>{button}</button>
                <ReCAPTCHA
                  className="invisible-recaptcha"
                  size="invisible"
                  ref={recaptchaRef}
                  sitekey={siteKey}
                  style={{ opacity: 0 }} // we don't want the recaptcha icon to appear across the site
                />
              </div>
            </div>
          </form>
        </div>
      )
    }

}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        wordpressWpSettings {
          title
          siteUrl
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            googleSiteKey
          }
        },
        allWordpressWpForm {
          nodes {
            wordpress_id
            formJson {
              recipientAddress
              autoFill
              button
              formFields {
                type
                required
                placeholder
                options {
                  label
                  value
                }
                name
                className
                accept
              }
            }
          }
        }
      }
    `}
    render={data => <Index data={data} {...props} />}
  />
)
