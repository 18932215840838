import React from 'react'
import { QuoteCartIcon } from '../Quote/QuoteCartIcon'
import './QuoteNavigation.scss'
import { navigate } from "gatsby"

export const QuoteNavigation = (props) => {
    const { activeStep, setQuoteType } = props
    const steps = ['Select your service','Select your product & extras','Your details']
    return (
        <div className="quote-navigation">
            <div className="nav">
                {steps && steps.map((step, index) => 
                    <div className={activeStep >= index ? 'active' : ''} key={index}>
                        <div className="link" onClick={activeStep === 2 ? () => navigate('/quote/') : event => setQuoteType(event, null)}>
                            <span className="step">{index + 1}</span><span className="m-hide">. {step}</span>
                        </div>
                        {index === 2 && <QuoteCartIcon/>}
                    </div>
                )}
            </div>
        </div>
    )
} 