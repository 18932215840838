import React from 'react'
import { Image } from '../Image'
import GatsbyLink from '../GatsbyLink'
import './MegaMenu.scss'

const MegaMenu = ({ item, megaClass }) => {
  const { children } = item
  return (
    <div className={`megaMenu ${megaClass}`}>
      <div className="intro">
        
        <div className="d-sm--none">
          {item.acf && item.acf.menu_image && <Image src={item.acf.menu_image} alt="" className="icon" position="relative" />}
          <p className="title">{item.title}</p>
          <p>{item.description}</p>
        </div>
        <GatsbyLink
          className="action"
          to={item.url}
          aria-label="Learn more"
        >
          Learn more
        </GatsbyLink>

 
      </div>
      <div className="content">
        <ul className="products">
          {children &&
            children.map((item, index) => {
              const { title, url, acf} = item
              if (title === 'Services') { //Services sub menu items
                return (
                  <li className="service-list" key={index}>
                    <ul>
                      {item.children && item.children.map((subItem, index) => 
                        <li className="service" key={index}>
                          <GatsbyLink to={subItem.url}>{subItem.title}</GatsbyLink>
                        </li>
                      )}
                    </ul>
                  </li>
                )
              } else {
                return (
                  <li className="product" key={index}>
                    <GatsbyLink to={url} className="product" aria-label={title}>
                      {acf && acf.menu_image && (
                        <div className="image"><Image src={acf.menu_image} /></div>
                      )}
                      <span>{title}</span>
                    </GatsbyLink>
                  </li>
                )
              }
            })}
        </ul>
      </div>
    </div>
  )
}
export default MegaMenu
