import React from 'react';
import { slugify } from '../../../utils/helpers'
import { Quote } from '../../Quote'

export const QuoteList = (props) => {
  const { field, value, handleChange } = props
  const {type, name, placeholder, required, className } = field
  return (
    <div className="quote-list">
      <Quote/>
    </div>
  )
}
