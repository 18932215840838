import React, { useState, useEffect, useRef } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import GatsbyLink from '../GatsbyLink'

import './HeaderNav.scss'
import MegaMenu from '../MegaMenu'

const HeaderNav = ({ className, navItems, megaMenu, productCategories }) => {
  return (
    navItems && (
      <ul className={className}>
        {megaMenu ? (
          <MegaMenuBtn
            navItems={navItems}
            productCategories={productCategories}
          />
        ) : (
          navItems.map((item, index) => (
            <li key={index}>
              <GatsbyLink
                to={item.url}
                target={item.target ? item.target : null}
                aria-label={item.title && item.title}
                decode={true}
              >
                {item.title && item.title}
              </GatsbyLink>
            </li>
          ))
        )}
      </ul>
    )
  )
}

const MegaMenuBtn = ({ navItems, productCategories }) => {
  const useComponentVisible = initialIsVisible => {
    const [isMegaOpen, setMegaOpen] = useState(initialIsVisible)
    const ref = useRef(null)

    const handleHideDropdown = event => {
      if (event.key === 'Escape') {
        setMegaOpen(false)
      }
    }

    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMegaOpen(false)
      }
    }

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown, true)
      document.addEventListener('click', handleClickOutside, true)
      return () => {
        document.removeEventListener('keydown', handleHideDropdown, true)
        document.removeEventListener('click', handleClickOutside, true)
      }
    })

    return { ref, isMegaOpen, setMegaOpen }
  }

  const { ref, isMegaOpen, setMegaOpen } = useComponentVisible(false)

  const megaClass = index => {
    return isMegaOpen === index ? 'active' : ''
  }

  return (
    <div className="megaMenuContainer" ref={ref}>
      {navItems.map((item, index) => (
        <li key={index}>
          <div
            className={`megaMenu-link ${megaClass(index)}`}
            onClick={() =>
              index === isMegaOpen ? setMegaOpen(false) : setMegaOpen(index)
            }
            onKeyDown={e => {
              if (e.keyCode === 13 && index === isMegaOpen) {
                setMegaOpen(false)
              } else if (e.keyCode === 13 && index !== isMegaOpen) {
                setMegaOpen(index)
              }
            }}
            role="button"
            tabIndex="0"
          >
            {item.title && item.title}
            <span className="menuMenu-chevron"/>
          </div>
          <MegaMenu
            item={item}
            megaClass={megaClass(index)}
            productCategories={productCategories}
          />
        </li>
      ))}
    </div>
  )
}

export default HeaderNav